import { useState, useEffect } from 'react';
import { loadStripe } from '@buddy-technology/ion-to-react';
import { queryStringToObject } from '@buddy-technology/buddy_helpers';
import {
	loadGA, loadSentry, loadUmami, reportError,
} from './models/analytics';
import { getION } from './models/api';
import { ACTION_TYPE, STAGE, APP_STATUS } from './models/Dictionary';
import OfferElement from './components/OfferElement';
import Loading from './components/Loading';
import ErrorBoundary from './components/ErrorBoundary';
import '@buddy-technology/ion-to-react/dist/index.css';
import './index.css';

const gaCode = process.env.REACT_APP_GA_CODE;
const defaultStage = process.env.REACT_APP_ACTIVE_ENV.toUpperCase(); // DEVELOPMENT | STAGING | PRODUCTION
const defaultStripeKey = process.env.REACT_APP_ION_WIDGET_PAY_KEY;

function App() {
	const [parent, setParent] = useState();
	const [ionProps, setIonProps] = useState({});
	const [stripe, setStripe] = useState();
	const [status, setStatus] = useState(APP_STATUS.LOADING);
	const [ION, setION] = useState();
	const [error, setError] = useState();
	const { partnerID: partnerId, ion: ionId, ionVersion } = ionProps;

	// grab stage from ionProps in case external users want to test, otherwise, use the default stage set by the environment.
	const stage = ionProps?.stage || defaultStage;

	const configureSentryScope = ({ ionName, partnerName, meta }) => {
		if (window?.Sentry) {
			window.Sentry.configureScope((scope) => {
				scope.setTags({
					ion: ionName,
					partner: partnerName,
				});
				scope.setExtra('meta', JSON.stringify(meta));
			});
		}
	};

	const updateIonProps = (options = {}) => {
		const {
			ion: ionName,
			partnerID: partnerName,
			meta = {},
			data = {},
			location = {}, // this is being passed in from the JS loader
		} = options;

		/* Inside an <iframe>, document.referrer is the same value as the href of the parent window's Window.location. Since we can't access window.parent.location, this is our best fallback. */
		const channelUrl = location.href || document.referrer;
		const { session = {} } = data;
		const sessionPropsToAdd = {
			stage,
			// we need to strip out localhost, otherwise the WAF will block any POST requests to the API.
			channelUrl: channelUrl?.includes('localhost') ? 'LOCAL_ENVIRONMENT' : channelUrl,
		};
		Object.assign(session, sessionPropsToAdd);
		data.session = session;
		// The point is to mutate our argument, so we're ok with param reassignment.
		// eslint-disable-next-line no-param-reassign
		options.data = data;
		configureSentryScope({ ionName, partnerName, meta });
		// log meta for quicker confirmation of meta props in live implementations
		setIonProps(options);
	};

	const setupPostMessage = async (event) => {
		// TODO: double check the origin before moving on.
		const { data: { action, payload = {} }, source } = event;
		if ([ACTION_TYPE.SETUP, ACTION_TYPE.UPDATE].includes(action)) {
			setParent(source);
			const stripeKey = payload.stripeKey || defaultStripeKey;
			const stripeInstance = await loadStripe(stripeKey);
			setStripe(stripeInstance);
			updateIonProps(payload);
		}
	};

	// THIS IS ONLY FOR DEVELOPMENT CONVENIENCE
	const setupFromQuery = async () => {
		const stripeKey = defaultStripeKey;
		const stripeInstance = await loadStripe(stripeKey);
		setStripe(stripeInstance);
		// grab any params from query string and update context.
		const queryData = queryStringToObject(window.location.search);
		updateIonProps(queryData);
	};

	const handleError = (err) => {
		reportError(err);
		let msg = err?.message || err?.error || err;
		if (typeof msg !== 'string') {
			msg = 'An unknown error occurred';
		}
		setError(msg);
		setStatus(APP_STATUS.ERROR);
	};

	const loadION = async (ionName, partner) => {
		if (!partner && !ionName) {
			return;
		}
		if (!partner) {
			handleError('Missing partner ID');
			return;
		}
		if (!ionName) {
			handleError('Missing ION ID');
			return;
		}

		try {
			const ion = await getION({
				ionID: ionName,
				ionVersion,
				partnerID: partner,
				stage,
			});
			setION(ion);
			setStatus(APP_STATUS.SUCCESS);
		} catch (err) {
			handleError(err);
		}
	};

	useEffect(() => {
		// load critical 3rd-party code on mount.
		loadSentry();
		loadUmami();
		if (defaultStage === STAGE.DEVELOPMENT) {
			setupFromQuery();
		}
		window.addEventListener('message', setupPostMessage);
		return () => {
			window.removeEventListener('message', setupPostMessage);
		};
	}, []);

	useEffect(() => {
		if (!ionId || !partnerId) {
			return;
		}
		loadION(ionId, partnerId);
		loadGA(gaCode, partnerId, ionId);
	}, [ionId, partnerId]);

	const STATUS = {
		LOADING: <Loading />,
		ERROR: <div className="text-center my-5 text-red-500">{error || 'An unknown error occurred'}</div>,
		SUCCESS: (
			<OfferElement
				ion={ION}
				stripe={stripe}
				parent={parent}
				ionProps={ionProps}
				stage={stage}
			/>
		),
	};

	return <ErrorBoundary>{STATUS[status] ?? STATUS.ERROR}</ErrorBoundary>;
}

export default App;
