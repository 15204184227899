import { useEffect, useRef } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import { ION_TO_JOI, flattenApplicationFields, createOrderPayload } from '@buddy-technology/ion-helpers';
import debounce from 'lodash/debounce';
import { useUpdateEffect } from 'react-use';
import {
	ACTION_TYPES, ACTION_SUB_TYPES, CALL_STATUSES, NAV_ACTIONS,
} from '../models/dictionary';
import { useCallContext, useEventContext, useDataContext } from '../context';
import usePostMessage from './usePostMessage';
import {
	convertFieldIDtoRHF,
	handleReactions,
	flattenObj,
} from '../utils';
import isOnlyTopLevelFields from '../utils/isOnlyTopLevelFields';
import logInfo from '../utils/logInfo';

const DEFAULT_RETURN = {
	onNext: null,
	isLoading: false,
	disableBack: false,
	shouldSkip: false,
};

// This function will return an object with two arrays: emptyRequiredFieldIds and fieldsToValidate. Just used inside our FIELD_CHANGE trigger to figure out out what fields to validate, and if we have empty required fields.
const getEmptyRequiredAndFieldsToValidate = ({
	fieldValues = [],
	convertedFieldIds = [],
	requiredFields = [],
	rawFieldIds = [],
}) => fieldValues.reduce((acc, value, index) => {
	// if the value is required and is empty, add the field id (null, false, and 0 are not considered empty)
	if (['', undefined].includes(value) && requiredFields.includes(rawFieldIds[index])) {
		acc.emptyRequiredFieldIds.push(convertedFieldIds[index]);
	} else {
		acc.fieldsToValidate.push(convertedFieldIds[index]);
	}
	return acc;
}, { emptyRequiredFieldIds: [], fieldsToValidate: [] });

const useAction = (action = {}, actionOptions = {}) => {
	const { debug } = useDataContext();
	const {
		control,
		// viewType, <-- will need for long-form view
		iframeSrc,
		ionId,
		navigate,
		getValues,
		partnerId,
		stage,
		validateFields,
	} = actionOptions;
	const {
		actionType,
		type,
		id,
		allowNext,
		allowBack,
		onSuccess,
		onError,
		onTimeout,
		requiredFields = [],
		optionalFields = [],
		isSuccessWhen,
		timeout,
	} = action;

	const {
		calls, executeCall, updateCall,
	} = useCallContext();

	const timeoutIdRef = useRef();
	const { onCustomMessage } = useEventContext();

	const rawActionFields = [...optionalFields, ...requiredFields];
	const actionFields = rawActionFields.map((el) => convertFieldIDtoRHF(el));
	const call = calls.value.find(({ id: callId }) => id === callId) || {};

	// subscribe to needed form fields (if they error, we don't trigger calls)
	useFormState({ control, name: actionFields });

	const actionFieldValues = useWatch({ control, name: actionFields }) || [];

	const runActionHandlers = ({
		reactions,
		error,
		data,
		source,
		origin,
	}) => {
		if (timeoutIdRef.current) {
			clearTimeout(timeoutIdRef.current);
		}

		// if no reactions, log the result to console
		// eslint-disable-next-line no-console
		const defaultReaction = error ? () => console.log(error) : () => console.log(`Action ${id} completed successfully.`);

		handleReactions({
			reactions,
			data,
			origin,
			source,
			defaultReaction,
			...actionOptions,
		});
	};

	const handleReceivedPostMessage = ({ data, origin, source }) => {
		const successFields = isSuccessWhen?.fields || [];
		const schema = ION_TO_JOI(flattenApplicationFields(successFields, '.'));
		const { error } = schema.validate(flattenObj({ data }), {
			abortEarly: true,
			allowUnknown: true,
		});
		const reactions = error ? onError : onSuccess;

		runActionHandlers({
			reactions,
			error,
			data,
			origin,
			source,
		});
	};

	// only listen for our parent if we the ion specifically stated a RESOLVE action, AND there's no iframe obj.
	const shouldListenForParent = (actionType === ACTION_TYPES.POST_MESSAGE)
		&& (type === ACTION_SUB_TYPES.RESOLVE)
		&& !iframeSrc;

	usePostMessage({
		hasAction: actionType === ACTION_TYPES.POST_MESSAGE,
		src: iframeSrc,
		parent: shouldListenForParent,
		callback: handleReceivedPostMessage,
	});

	const getPayload = () => {
		const isTopLevel = isOnlyTopLevelFields(actionFields);

		// if toplevel, grab the entire state objects needed.
		if (isTopLevel) {
			const fullState = getValues(); // returns object with keys as :: separated strings
			const fullPayload = createOrderPayload(fullState);

			// grab only the top level objects listed (eg: policy, customer)
			const payload = actionFields.reduce((acc, field) => {
				acc[field] = fullPayload[field];
				return acc;
			}, {});
			return payload;
		}

		// calling this programmatically to get the latest values
		const fieldValues = getValues(actionFields); // returns array of values when passed an array of field ids
		const fieldState = actionFields.reduce((acc, field, index) => {
			acc[field] = fieldValues[index]; // we're subscribed to actionFieldValues, so these will always be up to date
			return acc;
		}, {});
		const payload = createOrderPayload(fieldState);
		return payload;
	};

	const sendCallOrMessage = () => {
		const payload = getPayload();

		if (debug) {
			logInfo(`Executing action [${actionType}] with id: [${id}]:`, payload);
		}

		if (actionType === ACTION_TYPES.CALL) {
			executeCall({
				ionId,
				callId: id,
				payload,
				partnerId,
				stage,
			});
			return;
		}

		if (actionType === ACTION_TYPES.POST_MESSAGE) {
			const dataToSend = { id, data: payload };
			onCustomMessage(dataToSend);
			// no error possible when just sending messages
			runActionHandlers({
				reactions: onSuccess,
				data: { payload: dataToSend },
			});
		}
	};

	const executeActionForFieldChanges = debounce(async () => {
		// if there are no fields to validate at all, just execute the action.
		if (!actionFields.length) {
			sendCallOrMessage();
			return;
		}

		const { emptyRequiredFieldIds, fieldsToValidate } = getEmptyRequiredAndFieldsToValidate({
			fieldValues: actionFieldValues,
			convertedFieldIds: actionFields,
			requiredFields,
			rawFieldIds: rawActionFields,
		});

		// if we have some empty required fields, just trigger validation for what is not empty, but do not execute action.
		if (emptyRequiredFieldIds.length) {
			if (debug) {
				logInfo(`Field Change Action with id: [${id}] has empty required fields:`, emptyRequiredFieldIds, 'skipping action execution.');
			}
			// Only validate if we have fields to validate, since an empty array will trigger our entire form.
			if (fieldsToValidate.length) {
				// using raw trigger here to just validate the fields that have values
				validateFields(fieldsToValidate, { fieldsToTrigger: fieldsToValidate }); // don't need to await here
			}
			return;
		}

		// if our requiredFields are not empty, validate and execute if payload is valid
		const shouldSend = await validateFields(actionFields, { fieldsToTrigger: actionFields });

		if (shouldSend) {
			sendCallOrMessage();
		}
	}, 500, { leading: true });

	// ONLY RUN THIS ON LOAD
	useEffect(() => {
		if ([ACTION_TYPES.CALL, ACTION_TYPES.POST_MESSAGE].includes(actionType) && type === ACTION_SUB_TYPES.LOAD) {
			// Should only trigger our action's fields here, not other fields on the view.
			validateFields(actionFields, { fieldsToTrigger: actionFields }).then((isValid) => {
				if (isValid) {
					sendCallOrMessage();
				} else {
				// Since this only runs on load, we need to run our error handler if the payload fails validation
					runActionHandlers({
						reactions: onError,
						error: 'Action payload failed validation.',
					});
					if (debug) {
						logInfo(`Action with id: [${id}] failed validation.`);
					}
				}
			});
		}
	}, []);

	// This runs every time our required/optional fields change but not on mount.
	useUpdateEffect(() => {
		if ([ACTION_TYPES.CALL, ACTION_TYPES.POST_MESSAGE].includes(actionType) && type === ACTION_SUB_TYPES.FIELD_CHANGE) {
			executeActionForFieldChanges();
		}
	}, [...actionFieldValues]);

	// TODO: set up async views for long-form view modes.
	// We only want to return the cleanup function if handleReactions runs, so disabling this rule here.
	// eslint-disable-next-line consistent-return
	useEffect(() => {
		const isHandled = !!call.handled;

		const isSuccess = call.status === CALL_STATUSES.RESOLVED;
		const isError = call.status === CALL_STATUSES.ERROR;
		const callNeedsHandling = !isHandled && (isSuccess || isError);

		// if we have a timeout, set it up (for all action types, not just calls)
		if (timeout) {
			timeoutIdRef.current = setTimeout(() => {
				if (call) {
					updateCall(call.id, { status: CALL_STATUSES.ERROR });
				}
				const response = { error: `Action [${id}] timed out.` };
				runActionHandlers({
					// default to onError if no onTimeout is provided
					reactions: onTimeout || onError,
					data: { response },
					error: response.error,
				});
				if (debug) {
					logInfo(response.error);
				}
			}, timeout);
		}

		if (callNeedsHandling) {
			const reactions = isSuccess ? onSuccess : onError;
			const response = isSuccess ? call.response : { error: call.error };

			// update the call now, in case our action handlers navigate us away causing us to unmount.
			updateCall(call.id, { handled: true });

			runActionHandlers({
				reactions,
				data: { response },
				isSuccess,
			});

			if (debug) {
				logInfo('call resolved:', { call });
			}
		}

		return () => {
			if (timeoutIdRef.current) {
				clearTimeout(timeoutIdRef.current);
			}
		};
	}, [call.status, timeout, call.id, call.handled]);

	// shallow copy ok here since object is flat
	const returnValues = {
		...DEFAULT_RETURN,
	};

	returnValues.shouldSkip = !!(actionType === ACTION_TYPES.CALL && type === ACTION_SUB_TYPES.RESOLVE && call.handled);

	if (type === ACTION_SUB_TYPES.TRIGGER) {
		returnValues.onNext = () => {
			validateFields(actionFields).then((isValid) => {
				if (isValid) {
					sendCallOrMessage();
					if (allowNext) {
						navigate(NAV_ACTIONS.NEXT);
					}
				}
			});
		};
	}

	if (actionType === ACTION_TYPES.CALL) {
		const isCallPending = call.status === CALL_STATUSES.PENDING;
		returnValues.isLoading = !allowNext && isCallPending;
		// allowBack needs to be explicitly false to disable
		returnValues.disableBack = (allowBack === false) && isCallPending;
	}
	return returnValues;
};

export default useAction;
