import React, { useState, useEffect } from 'react';
import { Numbers } from '@buddy-technology/buddy_helpers';
import { useWatch } from 'react-hook-form';
import {
	CardElement, useStripe, useElements, CardNumberElement,
} from '@stripe/react-stripe-js';
import { createOrderPayload, calcIntervalPricing, PAYMENT_TERMS } from '@buddy-technology/ion-helpers';
import Button from './UI/Button';
import { purchasePolicy } from '../models/api';
import { PAYMENT_TYPES } from '../models/dictionary';
import { IF, RenderedView } from './helpers';
import { Markdown } from './UI';
import StripeInput from './UI/StripeInput';
import { useEventContext, useDataContext } from '../context';

function Checkout({
	checkoutViewInfo, // holds the view object info
	control,
	Controller,
	errors,
	flatApplication,
	getValues,
	handleSubmit,
	handleSuccess,
	includeCheckout,
	navigate,
	onOptIn,
	register,
	setValue,
	stage,
	variables,
	...viewProps
}) {
	const stripe = useStripe();
	const elements = useElements();
	const { sessionId } = useDataContext();
	const formValues = getValues();

	const contentBelowNav = checkoutViewInfo?.content?.filter(({ position }) => position === 'BELOW_NAV');

	const { nextButtonLabel, backButtonLabel } = checkoutViewInfo || {}; // checkoutViewInfo isn't always present. it's ION-dependent
	const defaultSubmitLabel = includeCheckout ? 'Buy Now' : 'Add to Cart';
	const initialSubmitLabel = nextButtonLabel || defaultSubmitLabel;

	const paymentType = formValues['payment::paymentType'];
	const shouldSkipPayment = paymentType === PAYMENT_TYPES.NO_COLLECT;

	const [premiumTotal, paymentOption] = useWatch({ control, name: ['policy::premiumTotal', 'policy::paymentOption'] });

	const [isLoading, setIsLoading] = useState(false);
	const [checkoutError, setCheckoutError] = useState();
	const [buttonLabel, setButtonLabel] = useState(initialSubmitLabel);

	const eventContext = useEventContext();
	const { eventCallback, viewType } = eventContext;
	useEffect(() => {
		if (viewType === 'paginated') {
			eventCallback('onCheckout', { timestamp: Date.now(), checkoutStatus: 'start', premium: Numbers.toUSD(premiumTotal) });
		}
	}, []);

	const getInvalidFieldsMessage = (error) => {
		// eslint-disable-next-line no-console
		console.log(error);
		let errorMsg = error;
		if (typeof error !== 'string') {
			const errorKeys = Object.keys(error);
			errorMsg = errorKeys.reduce((acc, current, i) => {
				const isLastIndex = i === errorKeys.length - 1;
				const field = flatApplication.find(({ id }) => id === current);
				const fieldLabel = field?.uiDisplay?.label;
				return `${acc} ${fieldLabel || ''}${isLastIndex ? '.' : ','}`;
			}, 'We encountered problems with the following fields: ');
		}
		return errorMsg;
	};

	const showErrorAndResetPayButton = (error) => {
		let errorMsg = error?.message || error?.error || error;
		// TODO: allow arrays
		if (typeof errorMsg !== 'string') {
			errorMsg = 'An unknown error occurred.';
		}
		setIsLoading(false);
		setButtonLabel(initialSubmitLabel);
		setCheckoutError(errorMsg);
		eventCallback('onCheckout', {
			timestamp: Date.now(), checkoutStatus: 'error', checkoutError: errorMsg, premium: Numbers.toUSD(premiumTotal),
		});
	};

	const handleCardError = (error) => {
		if (typeof window !== 'undefined' && window.Sentry) {
			window.Sentry.withScope((scope) => {
				scope.setTags({
					rejectionType: 'card',
				});
				window.Sentry.captureMessage(error?.message || error);
			});
		}
		showErrorAndResetPayButton(error);
	};

	const handleSubmitFormError = (error) => {
		const errorMsg = getInvalidFieldsMessage(error);
		if (typeof window !== 'undefined' && window.Sentry) {
			const messages = Object.values(error).map(({ message }) => message);
			window.Sentry.withScope((scope) => {
				scope.setTags({
					rejectionType: 'submit-form',
				});
				scope.setExtra('rawMessages', JSON.stringify(messages));
				window.Sentry.captureException(errorMsg);
			});
		}
		showErrorAndResetPayButton(errorMsg);
	};

	const handleCheckoutError = (error) => {
		if (typeof window !== 'undefined' && window.Sentry) {
			window.Sentry.withScope((scope) => {
				scope.setTags({
					rejectionType: 'order',
				});
				window.Sentry.captureException(error);
			});
		}
		showErrorAndResetPayButton(error);
	};

	const handleAddToCart = () => {
		setIsLoading(true);
		const payload = createOrderPayload(formValues);
		onOptIn(payload);
		setIsLoading(false);
	};

	const getPaymentToken = async () => {
		setButtonLabel('Validating Card');
		try {
			const cardElement = elements.getElement(CardElement);
			// mobile version inputs are slightly different, here we grab stripe token using the CardNumberElement
			const cardNumberElement = elements.getElement(CardNumberElement);
			// if cardElement is null (user input their cc info via mobile) let's pass along cardNumberElement instead
			const stripeElement = cardElement || cardNumberElement;
			const res = await stripe.createToken(stripeElement);
			return res;
		} catch (error) {
			return { error };
		}
	};

	const handleCheckout = async () => {
		setIsLoading(true);
		setCheckoutError(null);
		try {
			const payload = createOrderPayload(getValues());
			if (!shouldSkipPayment) {
				const { token, error } = await getPaymentToken();
				if (error) {
					handleCardError(error);
					return;
				}
				// this should be dynamic in the future. not all payment types will be PAYMENT_TOKEN.
				payload.payment = {
					paymentType: 'PAYMENT_TOKEN',
					paymentGateway: 'STRIPE',
					paymentToken: token.id,
				};
			}
			setButtonLabel('Processing');

			// only send allowed session data to API. (sometimes data is packed into session just for client use)
			payload.session = {
				sessionId,
				channelUrl: payload.session?.channelUrl || window.location.href,
				quoteId: payload.session?.quoteId,
				lastUpdate: payload.session?.lastUpdate,
			};

			payload.partnerId = payload.policy.partner;

			const createdPolicy = await purchasePolicy(payload, stage);

			const { orderId } = createdPolicy;
			setValue('createdPolicy', createdPolicy); // save the policy return object in case we need to grab data from it in the next screen.

			if (createdPolicy.session) {
				// overwrite session if necessary.
				setValue('session', createdPolicy.session);
			}

			setIsLoading(false);
			handleSuccess(orderId);
			eventCallback('onCheckout', {
				timestamp: Date.now(), checkoutStatus: 'success', premium: Numbers.toUSD(premiumTotal), orderId,
			});
		} catch (error) {
			handleCheckoutError(error);
		}
	};

	const createPolicyWithoutPayment = async (data) => {
		setCheckoutError(null);
		const formState = getValues();
		try {
			const payload = createOrderPayload({ ...formState, ...data });

			// only send allowed session data to API. (sometimes data is packed into session just for client use)
			payload.session = {
				sessionId,
				channelUrl: payload.session?.channelUrl || window.location.href,
				quoteId: payload.session?.quoteId,
				lastUpdate: payload.session?.lastUpdate,
			};

			payload.partnerID = payload.policy.partner;
			const createdPolicy = await purchasePolicy(payload, stage);

			const { orderId } = createdPolicy;
			handleSuccess(orderId);
			eventCallback('onCheckout', { timestamp: Date.now(), checkoutStatus: 'success', premium: Numbers.toUSD(premiumTotal) });
		} catch (error) {
			handleCheckoutError(error);
		}
	};

	const submitCallBack = includeCheckout ? handleCheckout : handleAddToCart;

	const intervalPricing = calcIntervalPricing(premiumTotal, paymentOption);
	const intervalPricingStatement = `Your first payment is ${Numbers.toUSD(intervalPricing.firstPayment)} with ${intervalPricing.remainingMonths} equal payments of ${Numbers.toUSD(intervalPricing.equalPaymentAmount)} following.`;

	const shouldShowPremiumTotal = premiumTotal && !Number.isNaN(parseFloat(premiumTotal));

	const hasCheckoutIframe = !!(checkoutViewInfo?.iframe?.src);

	const shouldShowStripeElement = includeCheckout && !hasCheckoutIframe && !shouldSkipPayment;

	return (
		<div id="checkout-wrapper">
			<IF condition={checkoutViewInfo}>
				<div className="my-4">
					<RenderedView
						{...checkoutViewInfo}
						{...viewProps}
						backButtonLabel={backButtonLabel}
						control={control}
						Controller={Controller}
						errors={errors}
						getValues={getValues}
						onCheckout={createPolicyWithoutPayment}
						navigate={navigate}
						nextButtonLabel={nextButtonLabel}
						register={register}
						stage={stage}
						setValue={setValue}
						variables={variables}
					/>
				</div>
			</IF>
			<IF condition={!checkoutViewInfo}>
				<h2 className="mt-4">Checkout</h2>
			</IF>
			{/* TODO: Show total/monthly/quarterly price */}
			<IF condition={shouldShowStripeElement}>
				<div className="mt-4">
					<IF condition={shouldShowPremiumTotal}>
						<p className="font-bold mb-4">{`Premium Total: ${Numbers.toUSD(premiumTotal)}`}</p>
						<IF condition={
							Object.keys(PAYMENT_TERMS).includes(paymentOption)
							&& (PAYMENT_TERMS[paymentOption] !== 1)
						}
						>
							<p>{intervalPricingStatement}</p>
						</IF>
					</IF>
					<StripeInput />
				</div>
			</IF>
			<IF condition={checkoutError}>
				<p className="input-error">{checkoutError}</p>
			</IF>
			<IF condition={!hasCheckoutIframe}>
				<div id="checkout-button-wrapper">
					<Button
						label={buttonLabel}
						isLoading={isLoading}
						// Log any errors to the console for easier debugging.
						onClick={handleSubmit(submitCallBack, handleSubmitFormError)}
						id="checkout-button"
					/>
					{/* added a back button here, removed it from StepThroughNav so these buttons can line up nicely */}
					<IF condition={viewType !== 'single-form'}>
						<Button
							variant="secondary"
							label={backButtonLabel || 'Back'}
							onClick={() => navigate('BACK')}
							id="checkout-back-button"
						/>
					</IF>

				</div>
			</IF>

			<IF condition={contentBelowNav?.length}>
				{contentBelowNav?.map((md, index) => (
					<Markdown
						getValues={getValues}
						variables={variables}
						// index is guaranteed to be stable here since it comes from static ion.
						// some IONs are using the same markdown in multiple places on the same view, so we need index to identify.
						// eslint-disable-next-line react/no-array-index-key
						key={`${md?.text}-${index}`}
						{...md}
						index={index}
						viewId={checkoutViewInfo.id}
						control={control}
						theme={viewProps.theme}
					/>
				))}
			</IF>
		</div>
	);
}

export default Checkout;
